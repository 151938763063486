import { toastController } from "@ionic/vue";
import { fbDB } from "@/firebase";
import { i18n } from "@/main";
import {user} from "@/auth";

async function openToast(type: string) {
  const toast = await toastController.create({
    message: i18n.global.t("reports.alerts."+type),
    duration: 5000,
    color: type == "error" ? "warning" : "success",
    buttons: [
     {
        icon: 'close',
        role: 'cancel',
        handler: () => {
          toast.dismiss();
        }
      }
    ]
  });
  return toast.present();
}

export async function reportProblem(data: any) {
  const newReportRef = fbDB.ref('reports').push();
  newReportRef.set({uid: user.value.uid,...data}).then(() => {
    openToast(data.type);
  }).catch(() => {
    openToast("error");
  });
  return;
}