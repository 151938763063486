<template>
  <div style="max-width: 500px; margin: auto">
    <ion-item-group>
      <ion-item-divider>
        <ion-label>
          <ion-skeleton-text animated style="width: 1rem"></ion-skeleton-text>
        </ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
        <ion-chip slot="end" style="width: 2.5rem"> </ion-chip>
      </ion-item>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
        <ion-chip slot="end" style="width: 2.5rem"> </ion-chip>
      </ion-item>
    </ion-item-group>
    <ion-item-group>
      <ion-item-divider>
        <ion-label>
          <ion-skeleton-text animated style="width: 1rem"></ion-skeleton-text>
        </ion-label>
      </ion-item-divider>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
        <ion-chip slot="end" style="width: 2.5rem"> </ion-chip>
      </ion-item>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
        <ion-chip slot="end" style="width: 2.5rem"> </ion-chip>
      </ion-item>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h2>
            <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
          </h2>
          <p>
            <ion-skeleton-text animated style="width: 35%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
        <ion-chip slot="end" style="width: 2.5rem"> </ion-chip>
      </ion-item>
    </ion-item-group>
    <div style="text-align: center; margin: 15px">
      <p>
        <ion-skeleton-text
          animated
          style="width: 33%; margin: auto"
        ></ion-skeleton-text>
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  IonAvatar,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonChip,
  IonItemDivider,
  IonSkeletonText,
} from "@ionic/vue";

export default defineComponent({
  components: {
    IonAvatar,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonChip,
    IonItemDivider,
    IonSkeletonText,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped>
ion-skeleton-text {
  border-radius: 20px;
}

h2 ion-skeleton-text {
  line-height: 18px;
}

.custom-skeleton ion-skeleton-text {
  line-height: 13px;
}

.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 5px;
}

ion-item {
  border-radius: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

ion-item-divider ion-label {
  border-radius: 20px;
}
</style>
