
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonItemGroup,
  IonLabel,
  IonItemDivider,
  IonAvatar,
  IonItem,
  IonChip,
  IonIcon,
  IonImg,
  IonBadge,
  modalController,
  isPlatform,
} from "@ionic/vue";

import {
  alertCircleOutline,
  personOutline,
  sunnyOutline,
  languageOutline,
  flameOutline,
  notificationsOutline,
  fingerPrintOutline,
  logoGitlab,
  keyOutline,
  mailOutline,
  ellipsisVertical,
} from "ionicons/icons";
import ContactProfileModal from "@/components/contacts/ContactProfileModal.vue";
import ContactsTabSkeleton from "@/components/contacts/ContactsTabSkeleton.vue";

import {
  myContacts,
  contactsCount,
  loadingMyContacts,
} from "@/components/contacts";

import router from "@/router";

export default {
  name: "ContactsTab",
  components: {
    ContactsTabSkeleton,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonItemGroup,
    IonLabel,
    IonItemDivider,
    IonAvatar,
    IonItem,
    IonChip,
    IonIcon,
    IonImg,
    IonBadge,
  },
  setup() {
    // loadMyContacts(); // called from Tabs.vue

    const goToMessages = (chatId: string) => {
      router.push(`/messages/${chatId}`);
    };

    const openContactProfile = async (contactInfo: any) => {
      const modal = await modalController.create({
        component: ContactProfileModal,
        cssClass: isPlatform("desktop") ? "desktopModalClass" : "",
        componentProps: { contactInfo: contactInfo },
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      isPlatform,
      goToMessages,
      openContactProfile,
      myContacts,
      contactsCount,
      loadingMyContacts,
      mailOutline,
      personOutline,
      sunnyOutline,
      languageOutline,
      flameOutline,
      notificationsOutline,
      fingerPrintOutline,
      logoGitlab,
      alertCircleOutline,
      keyOutline,
      ellipsisVertical,
    };
  },
};
