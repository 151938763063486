<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button color="primary" @click="closeModal()">{{
            $t("buttons.close")
          }}</ion-button>
        </ion-buttons>
        <ion-title v-if="profile">{{ profile.name }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <div v-if="loadingProfile">
        <ProfileSkeleton />
      </div>
      <div v-else>
        <div v-if="profile == null || errorOccured">
          nepodarilo sa nacitat profil :/
        </div>
        <div v-else>
          <ProfileHeader :profile="profile" :allPhotos="photos" />
          <div
            v-if="contactInfo.blocked"
            style="text-align: center; margin-bottom: 10px; margin-top: 0"
          >
            <ion-badge
              color="warning"
              style="font-weight: lighter"
              mode="ios"
              >{{ $t("contacts.contactBlocked") }}</ion-badge
            >
          </div>
          <div
            style="text-align: center; max-width: 500px; margin:auto; margin-bottom: 2rem;"
          >
            <ion-button
              mode="ios"
              @click="goToMessages()"
              color="primary"
              shape="round"
              style="width: 70%; height: 3rem"
            >
              <ion-icon slot="start" :icon="mailOutline"></ion-icon>
              {{ $t("contacts.messages") }}
            </ion-button>
            <ion-button
              mode="ios"
              color="medium"
              fill="outline"
              shape="round"
              @click="presentActionSheet()"
              style="height: 3rem"
            >
              <ion-icon slot="icon-only" :icon="ellipsisVertical"></ion-icon>
            </ion-button>
          </div>
          <ProfileBody :profile="profile" :allPhotos="photos" />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBadge,
  IonIcon,
  actionSheetController,
  modalController,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import {
  personOutline,
  locationOutline,
  briefcaseOutline,
  homeOutline,
  mailOutline,
  ellipsisVertical,
  callOutline,
} from "ionicons/icons";

import ProfileHeader from "@/components/profile/show/ProfileHeader.vue";
import ProfileBody from "@/components/profile/show/ProfileBodyC.vue";
import ProfileSkeleton from "@/components/profile/show/ProfileSkeleton.vue";

import { loadProfileByUid } from "@/components/profile/show";
import { fbDB } from "@/firebase";
import { user } from "@/auth";
import {
  removeContact,
  blockContact,
  unblockContact,
} from "@/components/contacts";
import router from "@/router";

import { useI18n } from "vue-i18n";
import { reportProblem } from "@/report";

export default defineComponent({
  name: "ContactProfileModal",
  props: {
    contactInfo: Object,
  },
  components: {
    IonPage,
    ProfileHeader,
    ProfileBody,
    ProfileSkeleton,
    IonButtons,
    IonButton,
    IonIcon,
    IonBadge,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
  },
  setup(props: any) {
    const { t } = useI18n({ useScope: "global" });

    const closeModal = async () => {
      const modal = await modalController.getTop();
      if (modal) {
        modal.dismiss();
      }
    };

    const profile = ref<{ [key: string]: any } | null>({});
    const photos = ref<string[]>([]);
    const loadingProfile = ref(true);
    const errorOccured = ref(false);
    const loadContactProfile = async () => {
      loadingProfile.value = true;
      try {
        if (props.contactInfo == null) {
          errorOccured.value = true;
        }
        const result = await loadProfileByUid(props.contactInfo.uid);

        if (result != null) {
          profile.value = result.profile;
          photos.value = result.allPhotos;
        } else {
          profile.value = null;
        }
      } catch (err) {
        errorOccured.value = true;
      } finally {
        loadingProfile.value = false;

        await fbDB
          .ref(`users/${user.value.uid}/contacts/${props.contactInfo.uid}/new`)
          .set(null);
      }
    };
    loadContactProfile();

    const goToMessages = async () => {
      await closeModal();
      router.push(`/messages/${props.contactInfo.chatId}`);
    };

    const presentActionSheet = async () => {
      if (!profile.value) {
        return;
      }
      const actionSheet = await actionSheetController.create({
        cssClass: "my-custom-class",
        buttons: [
          {
            text: t("contacts.delete"),
            role: "destructive",
            handler: async () => {
              const alert = await alertController.create({
                cssClass: "my-custom-class",
                header: t("contacts.delete"),
                message: t("contacts.deleteValidation"),
                buttons: [
                  {
                    text: t("buttons.close"),
                    role: "cancel",
                    cssClass: "secondary",
                  },
                  {
                    text: t("buttons.yes"),
                    handler: async () => {
                      removeContact(
                        props.contactInfo.uid,
                        props.contactInfo.chatId
                      );
                      closeModal();
                    },
                  },
                ],
              });
              return alert.present();
            },
          },
          {
            text: t("contacts.report"),
            role: "destructive",
            handler: async () => {
              const alert = await alertController.create({
                cssClass: "my-custom-class",
                header: t("contacts.report"),
                message: t("contacts.reportValidation"),
                inputs: [
                  {
                    name: "description",
                    cssClass: "contactReportDescriptionArea",
                    value: "",
                    type: "textarea",
                    placeholder: t("contacts.reportPlaceholder"),
                  },
                ],
                buttons: [
                  {
                    text: t("buttons.close"),
                    role: "cancel",
                    cssClass: "secondary",
                  },
                  {
                    text: t("buttons.report"),
                    handler: (inputData) => {
                      reportProblem({
                        type: "contact",
                        description: inputData.description.trim(),
                      });
                      closeModal();
                    },
                  },
                ],
              });
              return alert.present();
            },
          },
          {
            text: props.contactInfo.blocked
              ? t("contacts.unblock")
              : t("contacts.block"),
            handler: async () => {
              const alert = await alertController.create({
                cssClass: "my-custom-class",
                header: props.contactInfo.blocked
                  ? t("contacts.unblock")
                  : t("contacts.block"),
                message: props.contactInfo.blocked
                  ? ""
                  : t("contacts.blockValidation"),
                buttons: [
                  {
                    text: t("buttons.close"),
                    role: "cancel",
                    cssClass: "secondary",
                  },
                  {
                    text: t("buttons.yes"),
                    handler: async () => {
                      if (props.contactInfo.blocked) {
                        await unblockContact(
                          props.contactInfo.uid,
                          props.contactInfo.chatId
                        );
                      } else {
                        await blockContact(
                          props.contactInfo.uid,
                          props.contactInfo.chatId
                        );
                      }
                      closeModal();
                    },
                  },
                ],
              });
              return alert.present();
            },
          },
          {
            text: t("contacts.sendMessage"),
            handler: () => {
              goToMessages();
            },
          },
          {
            text: t("buttons.close"),
            icon: "close",
            role: "cancel",
          },
        ],
      });
      return actionSheet.present();
    };

    return {
      goToMessages,
      profile,
      presentActionSheet,
      photos,
      closeModal,
      loadingProfile,
      errorOccured,
      personOutline,
      locationOutline,
      briefcaseOutline,
      homeOutline,
      mailOutline,
      ellipsisVertical,
      callOutline,
    };
  },
});
</script>


<style>
.contactReportDescriptionArea {
  border: 1px solid !important;
  border-radius: 20px !important;
  padding: 1rem !important;
  height: 8rem !important;
}
</style>