<template>
  <ion-page>
    <ion-header :translucent="false">
      <ion-toolbar>
        <ion-title>{{ $t("tabs.contacts") }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ $t("tabs.contacts") }}</ion-title>
        </ion-toolbar>
        <!-- <ion-toolbar>
          <ion-searchbar ></ion-searchbar>
        </ion-toolbar> -->
      </ion-header>
      <div v-if="loadingMyContacts">
        <ContactsTabSkeleton />
      </div>
      <div v-else style="margin: auto; max-width: 500px; text-align: center">
        <div v-if="contactsCount == 0">
          <div style="text-align: center; margin: 2rem">
            <!-- <ion-icon :icon="alertCircleOutline" style="font-size: 64px;"></ion-icon> -->
            <ion-img style="width:200px; margin:auto;" src="/assets/empty_states/contacts.png"></ion-img>
            <h6 style="margin-top: 6px">{{$t('contacts.noContacts')}}</h6>
          </div>
        </div>
        <div v-else>
          <div
            :style="isPlatform('android') ? 'margin: 0.5rem' : ''"
            v-for="(contactsListInSection, letter) in myContacts"
            :key="letter"
          >
            <ion-item-group
              v-if="Object.keys(contactsListInSection).length > 0"
            >
              <ion-item-divider>
                <ion-label>{{ letter }}</ion-label>
              </ion-item-divider>
              <ion-item
                v-for="(contact, uid) in contactsListInSection"
                :key="uid"
                button
                detail="false"
              >
                <ion-avatar
                  @click="openContactProfile({ uid: uid, ...contact })"
                  slot="start"
                  style="
                    text-align: center;
                    background-color: #777;
                    height: 50px;
                    width: 50px;
                  "
                >
                  <div
                    v-if="!contact.profile.profilePhoto"
                    style="height: 100%"
                  >
                    <ion-icon
                      :icon="personOutline"
                      style="height: 100%; color: ghostwhite"
                    />
                  </div>
                  <img v-else :src="contact.profile.profilePhoto" />
                </ion-avatar>
                <ion-label
                  @click="openContactProfile({ uid: uid, ...contact })"
                >
                  <h2 style="position: relative">
                    {{ contact.profile.name }}
                    <ion-badge
                      v-if="contact.new"
                      style="position: absolute; bottom: 0; margin-left: 5px"
                      color="primary"
                      mode="ios"
                      >{{ $t("contacts.new") }}</ion-badge
                    >
                    <ion-badge
                      v-if="contact.blocked"
                      style="
                        position: absolute;
                        bottom: 0;
                        margin-left: 5px;
                        font-weight: lighter;
                      "
                      color="warning"
                      mode="ios"
                      >{{ $t("contacts.blocked") }}</ion-badge
                    >
                  </h2>
                  <p>
                    {{ $t("profile.work.options." + contact.profile.work) }}
                  </p>
                  <p>
                    {{
                      $t("profile.location.options." + contact.profile.location)
                    }}
                  </p>
                </ion-label>
                <ion-chip @click="goToMessages(contact.chatId)" slot="end">
                  <ion-icon
                    :icon="mailOutline"
                    color="dark"
                    style="margin: auto"
                  ></ion-icon>
                </ion-chip>
              </ion-item>
            </ion-item-group>
          </div>
          <div style="text-align: center; margin: 10px">
            <small style="color: #999"
              >{{ $t("contacts.contactsCount") }} {{ contactsCount }}</small
            >
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSearchbar,
  IonItemGroup,
  IonLabel,
  IonItemDivider,
  IonAvatar,
  IonItem,
  IonChip,
  IonIcon,
  IonImg,
  IonBadge,
  modalController,
  isPlatform,
} from "@ionic/vue";

import {
  alertCircleOutline,
  personOutline,
  sunnyOutline,
  languageOutline,
  flameOutline,
  notificationsOutline,
  fingerPrintOutline,
  logoGitlab,
  keyOutline,
  mailOutline,
  ellipsisVertical,
} from "ionicons/icons";
import ContactProfileModal from "@/components/contacts/ContactProfileModal.vue";
import ContactsTabSkeleton from "@/components/contacts/ContactsTabSkeleton.vue";

import {
  myContacts,
  contactsCount,
  loadingMyContacts,
} from "@/components/contacts";

import router from "@/router";

export default {
  name: "ContactsTab",
  components: {
    ContactsTabSkeleton,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonItemGroup,
    IonLabel,
    IonItemDivider,
    IonAvatar,
    IonItem,
    IonChip,
    IonIcon,
    IonImg,
    IonBadge,
  },
  setup() {
    // loadMyContacts(); // called from Tabs.vue

    const goToMessages = (chatId: string) => {
      router.push(`/messages/${chatId}`);
    };

    const openContactProfile = async (contactInfo: any) => {
      const modal = await modalController.create({
        component: ContactProfileModal,
        cssClass: isPlatform("desktop") ? "desktopModalClass" : "",
        componentProps: { contactInfo: contactInfo },
        swipeToClose: true,
      });
      return modal.present();
    };

    return {
      isPlatform,
      goToMessages,
      openContactProfile,
      myContacts,
      contactsCount,
      loadingMyContacts,
      mailOutline,
      personOutline,
      sunnyOutline,
      languageOutline,
      flameOutline,
      notificationsOutline,
      fingerPrintOutline,
      logoGitlab,
      alertCircleOutline,
      keyOutline,
      ellipsisVertical,
    };
  },
};
</script>


<style scoped>
ion-item {
  border-radius: 20px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>